.work-page {
  min-height: 90vh;
  position: relative;
  margin-bottom: 5vh;
  align-items: center;
}

.work-apps {
  display: flex;
  padding-top: 5%;
}

.work-wrapper {
  width: 27.5%;
  min-width: 350px;
  margin: auto;
  margin-bottom: 2.5%;
  box-shadow: 0px 1px 2px 0px rgba(31,24,24,0.75);
} 

.work-header {
  height: 25%;
  color: white;
  position: absolute;
  top:0;
  left:0;
  font-size: 1em;
  margin-left: 2.5%;
  margin-top: 1%;
}

.work-description {
  left: 0;
  height: 80%;
  margin-top: 30%;
  margin-right: 2.5%;
  font-size: small;
  position: absolute;
  margin-left: 2.5%;
  margin-bottom: 1%;
  color: rgb(173, 172, 172);
}

.work-description p {
  margin-bottom: 5%;
}

.work-page .container:hover .overlay {
  opacity: 0.8;
}

.work-header h1 {
  font-size: large;
}

.work-header h3 {
  font-size: medium;
  color: rgb(207, 205, 205);
}

.work-description a {
  color: inherit;
}

.work-description span {
  margin-left: 1.5%;
  font-style: italic;
}

