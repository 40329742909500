.container {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .4s;
  background-color: black;
}

.container:hover .overlay {
  opacity: 0.6;
}

.card-image {
  max-width: 100%;
  max-height: 100%;
  padding: 20%;
}