* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  html {
    font-family: "Montserrat", sans-serif;
    scroll-behavior: smooth;
  }

  body {
    background: #f8f4f4;
    padding: 0%;
  }
  
  a {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .page {
    /* min-height: 90vh; */
    position: relative;
    margin-bottom: 5vh;
  }
  
  .page-anchor {
    position: absolute;
    top: -10vh;
  }
  
  h2 {
    font-size: small;
    color: grey;
    align-items: center;
  }

  .page-title {
    font-style: italic;
    padding-left: 2.5%;
    padding-top: 2.5%;
    font-size: max(3vw, 200%);
  }

  .page-title span {
    background-color: rgb(64, 82, 162);
    color: #f8f4f4;
    padding-bottom: .25%; 
    padding-left: .25%;
    padding-right: 1%; 
    display: inline-block;
  }