nav {
  align-self: flex-start;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  background: rgb(64, 82, 162);
  position: sticky;
  z-index: 1;
  top: 0;
}

.navbar {
  font-size: 1.1em;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-link{
  color: white;
}

.nav-link:hover {
  color: white;
  background-color: black;
}

.nav-link:active {
  background-color: rgb(107, 103, 79);
}