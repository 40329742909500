  .edu-wrapper {
    width: 80%;
    /* max-height: 80vh;
    min-height: 350px; */
    min-width: 750px;
    display: flex;
    margin: auto;
    margin-top: 2.5%;
    margin-bottom: 1.5%;
    box-shadow: 0px 1px 2px 0px rgba(31,24,24,0.75);
  }

  .edu-logo {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .edu-label {
    color: black;
    transition: 0.2s;
    font-size: max(2vw, 100%);
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .edu-label h1 {
    margin-bottom: .5%;
  }

  .edu-label h2 {
    margin-left: 1%;
    margin-bottom: .5%;
    font-size: 85%;
    color: rgb(93, 93, 93);
  }

  .container:hover .edu-label {
    opacity: 0;
  }

  .edu-description {
    margin-left: 40%;
    font-size: 1.75vw;
    position: relative;
    color: white;
  }

  .edu-description h1 {
    margin-top: 1%;
    margin-bottom: 2.5%;
  }

  .edu-description h2 {
    margin-left: 1%;
    font-size: 85%;
    color: rgb(201, 201, 201);
  }

  .edu-stack {
    /* margin-top: 7.5%; */
    margin-right: 2.5%;
  }

  .edu-description p {
    margin-left: 1%;
    font-size: 70%;
    color: rgb(149, 149, 149);
  }

  .edu-description ul {
    margin-top: 1%;
    margin-left: 2.5%;
    column-count: 3;
    list-style: none;
  }

  .edu-description li {
    margin-left: 2.5%;
    font-size: 75%;
    list-style-type: circle;
  }

  .edu-description a {
    color: rgb(186, 184, 184);
  }

  .edu-description a:hover {
    background-color: white;
    color: black;
    
  }  

  .edu-description a:active {
    background-color: yellow;
    color: black;
  } 