.footer {
  min-height: 20vh;
  background-color: rgba(56,72,142,255);
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-stack {
  position: relative;
  padding-top: 2%;
  color: white;
}

footer {
  text-align: center;
  font-size: small;
  color: rgb(151, 151, 151);
  margin-bottom: 2.5%;
  margin-top: 2.5%;
}

.footer-button:hover {
  background-color: rgba(64, 82, 162, 0.807);
  border-radius: 50%;
}